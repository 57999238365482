//Grab the buttons
let toggleNormal = document.getElementsByClassName("toggler-normal");
let toggleRotate = document.getElementsByClassName("toggler-spin-360");
let toggleCC = document.getElementsByClassName("toggler-rotate-cc");
let toggleC = document.getElementsByClassName("toggler-rotate-c");
let toggleArrowLeft = document.getElementsByClassName(
  "toggler-rotate-cc-arrow-left"
);
let toggleArrowRight = document.getElementsByClassName(
  "toggler-rotate-c-arrow-right"
);
let toggleArrowUp = document.getElementsByClassName(
  "toggler-rotate-c-arrow-up"
);
let toggleArrowDown = document.getElementsByClassName(
  "toggler-rotate-cc-arrow-down"
);

/*Try and toggle classes*/
/*Use try catch to avoid errors if button is not on page. Using get by class name then going through the array and adding listeners will make it so that multiple instances of the button will work on the same page.*/

try {
  for (let i = 0; i < toggleNormal.length; i++) {
    toggleNormal[i].addEventListener("click", function () {
      this.classList.toggle("normal-toggle");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleRotate.length; i++) {
    toggleRotate[i].addEventListener("click", function () {
      this.classList.toggle("rotate-360");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleCC.length; i++) {
    toggleCC[i].addEventListener("click", function () {
      this.classList.toggle("rotate-cc");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleC.length; i++) {
    toggleC[i].addEventListener("click", function () {
      this.classList.toggle("rotate-c");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleArrowLeft.length; i++) {
    toggleArrowLeft[i].addEventListener("click", function () {
      this.classList.toggle("rotate-cc-arrow-left");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleArrowRight.length; i++) {
    toggleArrowRight[i].addEventListener("click", function () {
      this.classList.toggle("rotate-c-arrow-right");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleArrowUp.length; i++) {
    toggleArrowUp[i].addEventListener("click", function () {
      this.classList.toggle("rotate-c-arrow-up");
    });
  }
} catch {}

try {
  for (let i = 0; i < toggleArrowDown.length; i++) {
    toggleArrowDown[i].addEventListener("click", function () {
      this.classList.toggle("rotate-cc-arrow-down");
    });
  }
} catch {}

document.addEventListener("DOMContentLoaded", function () {
  try {
    const navbar = document.getElementById("navbar"); // Replace with your navbar ID
    const navbarHeight = navbar.offsetHeight;

    const links = document.querySelectorAll("a[href^='#']");

    links.forEach((link) => {
      link.addEventListener("click", function (event) {
        event.preventDefault();
        if (this.hash !== "" || null) {
          const target = document.querySelector(this.hash);
          const targetOffset = target.getBoundingClientRect().top;
          window.scrollTo({
            top: targetOffset - navbarHeight,
            behavior: "smooth",
          });
        }
      });
    });
  } catch (error) {
    //console.log(error);
  }
});
